@import url(https://fonts.googleapis.com/css?family=Nunito|Quicksand);
nav.navbar {
    text-transform: uppercase;
    display: flex;
    background: transparent;
    color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    transition: background 500ms;
    z-index: 4;
}

nav.navbar.scrolled {
    background-color: #184C51;
    box-shadow: 0 5px 20px rgb(37, 37, 37);
}

nav.navbar div:not(.menu-btn) {
    flex: 1 1;
}

.nav-links {
    display: flex;
}

.nav-links a {
    flex: 1 1;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
}

.nav-links a:hover {
    color: rgb(139, 133, 133);
}

.navbar #brand {
    flex: 2 1;
    padding-left: 1rem;
}

.navbar #brand a {
    color: #fff;
    text-decoration: none;
}

.navbar #brand a:hover {
    color: rgb(139, 133, 133);
}

@media screen and (max-width: 804px) {
    .nav-links {
        display: none;
    }

    nav.navbar div {
        flex: none;
    }

    .menu-btn {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all .5s ease-in-out;
        height: 80px;
        margin-right: 1rem;
    }

    .hamburger-menu {
        width: 50px;
        height: 6px;
        background-color: #fff;
        border-radius: 5px;
        transition: all .5s ease-in-out;
    }

    .hamburger-menu::before, .hamburger-menu::after {
        content: '';
        position: absolute;
        width: 50px;
        height: 6px;
        background-color: #fff;
        border-radius: 5px;
        transition: all .5s ease-in-out;
    }

    .hamburger-menu::before {
        transform: translateY(-16px);
    }

    .hamburger-menu::after {
        transform: translateY(16px);
    }

    .menu-btn.open .hamburger-menu {
        transform: translateX(-50px);
        background: transparent;
    }
    
    .menu-btn.menu-btn.open .hamburger-menu::before {
        transform: rotate(45deg) translate(30px, -30px);
    }

    .menu-btn.open .hamburger-menu::after {
        transform: rotate(-45deg) translate(30px, 30px);
    }
}

.dropdown {
    position: absolute;
    top: 65px;
    width: 10rem;
    transform: translateX(-45%);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 0 10px #000;
}

.menu-item {
    height: 25px;
    display: flex;
    align-items: center;
    transition: background .5s;
    padding: 0.5rem;
    text-decoration: none;
    color: #000;
    border-radius: 5px;
}

.menu-item:hover {
    background-color: rgb(177, 177, 177);
}
header {
    position: relative;
    color: white;
    margin-bottom: 7rem;
    z-index: 2;
}

.header-image {
    margin-top: -100px;
    position: relative;
    z-index: -1;
    -webkit-filter: blur(5px);
            filter: blur(5px);
    height: 15rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center; */
}

header .header-title {
    font-family: 'Quicksand', serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-weight: 400;
    z-index: 2;
    text-align: center;
    text-shadow: 0 0 5px black;
}

header img {
    position: absolute;
    z-index: 2;
    height: 11rem;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.article-card {
    margin: 2rem 5rem;
    box-shadow: 0 0 10px rgb(177, 177, 177);
    border-radius: 5px;
    width: 40rem;
}

.article-card time {
    color: rgb(133, 130, 130);
    padding: 1rem 2.5rem;
}

.card-title {
    font-weight: 400;
    padding: 1rem 2.5rem;
    margin-top: 0rem;
}

.card-content a {
    text-decoration: none;
    color: #000;
}

.card-content a:hover {
    color: rgb(97, 97, 97);
}

.card-image {
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    border-radius: 5px;
    margin-bottom: .5rem;
}

@media screen and (min-width: 1800px) {
    .article-card {
        margin: 2rem 1rem;
    }
    .article-card {
        width: 35rem;
    }
}

@media screen and (max-width: 2050px) {
    .article-card {
        width: 35rem;
    }
}

@media screen and (max-width: 675px) {
    .article-card {
        width: 100%;
    }
}
.home main {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
}

.article-section {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-wrap: wrap;
    grid-gap: 0;
    gap: 0;
}

.home-loader {
    flex: 1 1;
    display: flex;
    justify-content: center;
}

article.main-post {
    margin: 0 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #efefef;
    padding: 2rem 3rem;
    border-radius: 20px;
    font-size: 2.5em;
}

article.main-post a {
    text-decoration: none;
    color: #000;
}

article.main-post a:hover {
    color: #7a7a7a;
}

article.main-post img {
    max-width: 100%;
    align-self: center;
    justify-self: center;
    border-radius: 10px;
    margin-right: 1rem;
}

@media screen and (max-width: 1800px) {
    article.main-post {
        grid-template-columns: 1fr;
        font-size: 1.5em;
        text-align: center;
    }
}

@media screen and (max-width: 675px) {
    article.main-post {
        width: 70vw;
    }

    .article-section {
        width: 90%;
        justify-items: center;
    }
}
main.article-main {
    display: flex;
    justify-content: center;
}

main.article-main .article-text{
    margin: 0 5rem;
    width: 35vw;
    text-align: left;
    font-size: 1.5em;
    background-color: #efefef;
    padding: 1rem 3rem;
    border-radius: 20px;
    margin-bottom: 2rem;
}

section.other-articles {
    margin-top: 0;
    text-align: center;
}

@media screen and (max-width: 1630px) {
    main.article-main {
        flex-direction: column;
    }

    main.article-main .article-text {
        width: auto;
        margin-right: 2rem;
    }

    section.other-articles {
        display: flex;
        flex-wrap: wrap;
    }

    section.other-articles h1 {
        flex: 0 0 100%;
    }
}
main.contact {
    height: 100%;
    width: 100%;
}

.contact-form {
    margin: 0 auto;
    width: 35rem;
}

.contact-form .form-group {
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;
}

.contact-form .form-group label {
    font-size: 2em;
}

.contact-input {
    margin-top: .5rem;
    border: 1px solid #adadad;
    border-radius: 5px;
    font-size: 1.5em;
    padding: .2rem;
}

.contact-input:focus {
    box-shadow: inset 0 0 10px #adadad;
}

.contact-form button {
    width: 7rem;
    border: none;
    background-color: rgb(0, 183, 255);
    color: #fff;
    border-radius: 2em;
    padding: .3rem 1rem;
    font-size: 1.5rem;
}

@media screen and (max-width: 575px) {
    .contact-form {
        width: 80vw;
    }
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #184C51;
  outline: 1px solid slategrey;
}
