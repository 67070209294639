main.article-main {
    display: flex;
    justify-content: center;
}

main.article-main .article-text{
    margin: 0 5rem;
    width: 35vw;
    text-align: left;
    font-size: 1.5em;
    background-color: #efefef;
    padding: 1rem 3rem;
    border-radius: 20px;
    margin-bottom: 2rem;
}

section.other-articles {
    margin-top: 0;
    text-align: center;
}

@media screen and (max-width: 1630px) {
    main.article-main {
        flex-direction: column;
    }

    main.article-main .article-text {
        width: auto;
        margin-right: 2rem;
    }

    section.other-articles {
        display: flex;
        flex-wrap: wrap;
    }

    section.other-articles h1 {
        flex: 0 0 100%;
    }
}