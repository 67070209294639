main.contact {
    height: 100%;
    width: 100%;
}

.contact-form {
    margin: 0 auto;
    width: 35rem;
}

.contact-form .form-group {
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;
}

.contact-form .form-group label {
    font-size: 2em;
}

.contact-input {
    margin-top: .5rem;
    border: 1px solid #adadad;
    border-radius: 5px;
    font-size: 1.5em;
    padding: .2rem;
}

.contact-input:focus {
    box-shadow: inset 0 0 10px #adadad;
}

.contact-form button {
    width: 7rem;
    border: none;
    background-color: rgb(0, 183, 255);
    color: #fff;
    border-radius: 2em;
    padding: .3rem 1rem;
    font-size: 1.5rem;
}

@media screen and (max-width: 575px) {
    .contact-form {
        width: 80vw;
    }
}