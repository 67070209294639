nav.navbar {
    text-transform: uppercase;
    display: flex;
    background: transparent;
    color: #fff;
    position: sticky;
    top: 0;
    transition: background 500ms;
    z-index: 4;
}

nav.navbar.scrolled {
    background-color: #184C51;
    box-shadow: 0 5px 20px rgb(37, 37, 37);
}

nav.navbar div:not(.menu-btn) {
    flex: 1;
}

.nav-links {
    display: flex;
}

.nav-links a {
    flex: 1;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
}

.nav-links a:hover {
    color: rgb(139, 133, 133);
}

.navbar #brand {
    flex: 2;
    padding-left: 1rem;
}

.navbar #brand a {
    color: #fff;
    text-decoration: none;
}

.navbar #brand a:hover {
    color: rgb(139, 133, 133);
}

@media screen and (max-width: 804px) {
    .nav-links {
        display: none;
    }

    nav.navbar div {
        flex: none;
    }

    .menu-btn {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all .5s ease-in-out;
        height: 80px;
        margin-right: 1rem;
    }

    .hamburger-menu {
        width: 50px;
        height: 6px;
        background-color: #fff;
        border-radius: 5px;
        transition: all .5s ease-in-out;
    }

    .hamburger-menu::before, .hamburger-menu::after {
        content: '';
        position: absolute;
        width: 50px;
        height: 6px;
        background-color: #fff;
        border-radius: 5px;
        transition: all .5s ease-in-out;
    }

    .hamburger-menu::before {
        transform: translateY(-16px);
    }

    .hamburger-menu::after {
        transform: translateY(16px);
    }

    .menu-btn.open .hamburger-menu {
        transform: translateX(-50px);
        background: transparent;
    }
    
    .menu-btn.menu-btn.open .hamburger-menu::before {
        transform: rotate(45deg) translate(30px, -30px);
    }

    .menu-btn.open .hamburger-menu::after {
        transform: rotate(-45deg) translate(30px, 30px);
    }
}

.dropdown {
    position: absolute;
    top: 65px;
    width: 10rem;
    transform: translateX(-45%);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 0 10px #000;
}

.menu-item {
    height: 25px;
    display: flex;
    align-items: center;
    transition: background .5s;
    padding: 0.5rem;
    text-decoration: none;
    color: #000;
    border-radius: 5px;
}

.menu-item:hover {
    background-color: rgb(177, 177, 177);
}