.home main {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
}

.article-section {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 0;
}

.home-loader {
    flex: 1;
    display: flex;
    justify-content: center;
}

article.main-post {
    margin: 0 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #efefef;
    padding: 2rem 3rem;
    border-radius: 20px;
    font-size: 2.5em;
}

article.main-post a {
    text-decoration: none;
    color: #000;
}

article.main-post a:hover {
    color: #7a7a7a;
}

article.main-post img {
    max-width: 100%;
    align-self: center;
    justify-self: center;
    border-radius: 10px;
    margin-right: 1rem;
}

@media screen and (max-width: 1800px) {
    article.main-post {
        grid-template-columns: 1fr;
        font-size: 1.5em;
        text-align: center;
    }
}

@media screen and (max-width: 675px) {
    article.main-post {
        width: 70vw;
    }

    .article-section {
        width: 90%;
        justify-items: center;
    }
}