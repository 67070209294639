header {
    position: relative;
    color: white;
    margin-bottom: 7rem;
    z-index: 2;
}

.header-image {
    margin-top: -100px;
    position: relative;
    z-index: -1;
    filter: blur(5px);
    height: 15rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center; */
}

header .header-title {
    font-family: 'Quicksand', serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-weight: 400;
    z-index: 2;
    text-align: center;
    text-shadow: 0 0 5px black;
}

header img {
    position: absolute;
    z-index: 2;
    height: 11rem;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}