.article-card {
    margin: 2rem 5rem;
    box-shadow: 0 0 10px rgb(177, 177, 177);
    border-radius: 5px;
    width: 40rem;
}

.article-card time {
    color: rgb(133, 130, 130);
    padding: 1rem 2.5rem;
}

.card-title {
    font-weight: 400;
    padding: 1rem 2.5rem;
    margin-top: 0rem;
}

.card-content a {
    text-decoration: none;
    color: #000;
}

.card-content a:hover {
    color: rgb(97, 97, 97);
}

.card-image {
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    border-radius: 5px;
    margin-bottom: .5rem;
}

@media screen and (min-width: 1800px) {
    .article-card {
        margin: 2rem 1rem;
    }
    .article-card {
        width: 35rem;
    }
}

@media screen and (max-width: 2050px) {
    .article-card {
        width: 35rem;
    }
}

@media screen and (max-width: 675px) {
    .article-card {
        width: 100%;
    }
}